import * as React from 'react';
import { GridColDef, GridSortItem } from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import NiobiTable from '../../components/NiobiTable';
import { Audit } from './Audit';

export default function AuditTable(props: {
  isLoading: boolean,
  audits: Audit[],
  totalNumItems: number,
  onQueryChange: (paginationModel?: GridPaginationModel, sortModel?: GridSortItem[]) => any,
}) {
  const {
    isLoading,
    onQueryChange,
    totalNumItems,
    audits,
  } = props;
  const navigator = useNavigate();

  const handleRowClick = (params:any) => {
    console.log('handleRowClick I am getting here', params.row);
    navigator(`/entities/${params.row.name}`, {
      state: {
        entityName: params.row.name,
        entityId: params.row.id,
        user:
        {
          name: params.row.user.name,
          email: params.row.user.email,
        },
      },
    });
  };
  return (
    <NiobiTable
      isLoading={isLoading}
      columns={columns()}
      rows={audits}
      handleOnRowClick={handleRowClick}
      totalNumItems={totalNumItems}
      onQueryChange={onQueryChange}
    />
  );
}

const columns = (): GridColDef[] => [
  { field: 'id', headerName: 'Log ID', width: 70 },
  { field: 'event', headerName: 'Event', width: 180 },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
  {
    field: 'performedBy',
    headerName: 'Done By',
    width: 180,
  },
  {
    field: 'entityId',
    headerName: 'File/ Entity',
    width: 180,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 160,
    valueFormatter: (params) => moment(params.value).format('DD MMM YYYY, HH:mm'),
  },
];
