import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  CheckCircle, CloudUpload, Feedback, PendingActions,
} from '@mui/icons-material';
import { DocumentStatus } from './Document';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminFileStorageApi } from './NiobiAdminFileStorageApi';

function NewFileUploadDialog(props: {
  open: boolean,
  userId: string,
  documentType: string,
  onClose: VoidFunction,
}) {
  const {
    open, onClose, userId, documentType,
  } = props;

  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminFileStorageApi(auth.user?.accessToken, auth.logout),
    [auth],
  );

  const [formValues, setFormValues] = useState({
    userId,
    status: '',
    documentType: '',
    type: 'business_documents',
  });

  const [files, setFiles] = useState([] as any[]);

  useEffect(() => {
    if (userId && documentType) {
      setFormValues((prevValues) => ({
        ...prevValues,
        userId,
        documentType,
        type: 'business_documents',
      }));
    }
  }, [userId, documentType]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleFileTitleChange = (index: number, event: any) => {
    const { value } = event.target;
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index].title = value;
      return updatedFiles;
    });
  };

  const onDrop = (acceptedFiles: any) => {
    const newFiles = acceptedFiles.map((file: any) => ({
      file,
      name: file.name,
      title: '', // Add title field for each file
      status: 'pending',
    }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const getBase64 = (fileToUpload: any, callBack: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileToUpload);
    reader.onload = () => {
      callBack(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  };

  const uploadFile = (fileObj: any) => {
    const { file, name, title } = fileObj;

    getBase64(file, (result: any) => {
      const fileData = {
        ...formValues,
        fileName: name,
        title, // Use the updated title field
        file: result,
      };

      setFiles((prevFiles) => prevFiles.map((f) => (f.name === name ? { ...f, status: 'uploading' } : f)));

      api
        .uploadNewFile(fileData)
        .then(() => {
          setFiles((prevFiles) => prevFiles.map((f) => (f.name === name ? { ...f, status: 'success' } : f)));
        })
        .catch(() => {
          setFiles((prevFiles) => prevFiles.map((f) => (f.name === name ? { ...f, status: 'error' } : f)));
        });
    });
  };

  const onConfirm = () => {
    files.forEach((fileObj) => {
      if (fileObj.status === 'pending') {
        uploadFile(fileObj);
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
    },
    multiple: true,
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formValues.status}
                onChange={handleChange}
                label="Status"
              >
                {Object.values(DocumentStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getRootProps()}
              sx={{
                border: '2px dashed #1976d2',
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              {
              // eslint-disable-next-line react/jsx-props-no-spreading
                <input {...getInputProps()} />
              }
              {isDragActive ? (
                <Typography variant="body1">Drop the files here...</Typography>
              ) : (
                <Typography variant="body1">
                  Drag & drop some files here, or click to select files
                </Typography>
              )}
            </Box>

            {files.length > 0 && (
              <Box sx={{ marginTop: '20px' }}>
                <Typography variant="h6">Files to Upload</Typography>
                <List>
                  {files.map((fileObj, index) => (
                    <ListItem key={fileObj.name}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <TextField
                          label="Title"
                          value={fileObj.title}
                          onChange={(e) => handleFileTitleChange(index, e)}
                          fullWidth
                          margin="normal"
                        />
                        <span>{fileObj.name}</span>
                      </div>
                      {fileObj.status === 'pending' && <span><PendingActions color="warning" /></span>}
                      {fileObj.status === 'uploading' && <span><CloudUpload color="info" /></span>}
                      {fileObj.status === 'success' && <span style={{ color: 'green' }}><CheckCircle color="success" /></span>}
                      {fileObj.status === 'error' && <span style={{ color: 'red' }}><Feedback color="error" /></span>}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Close</Button>
        <Button color="primary" onClick={onConfirm} autoFocus>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewFileUploadDialog;
