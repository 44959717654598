import { EntityStatus } from './Entity';
import { NiobiAdminEntityFileApi } from '../entityfiles/NiobiAdminEntityFileApi';
import { EntityFileStatus } from '../entityfiles/EntityFile';
import { NiobiAdminEntityApi } from './NiobiAdminEntityApi';

export enum EntityStatusAction {
  BANKCUSTOMER = 'Mark as Bank Customer',
  NONBANKCUSTOMER = 'Mark as non Bank Customer',
  VERIFY = 'Verify',
  REJECT = 'Reject',
  SUBMITDOCUMENTS = 'Submit Documents to Choice',
  SUBMITENTITY = 'Submit Entity Onboarding to Choice',
  VERIFYLEVELONE = 'Verify Level 1',
}

const Localuser = localStorage.getItem('USER');

console.log('***** user:', Localuser);
export async function getEntityStatusActions(
  fileApi: NiobiAdminEntityFileApi,
  entityApi: NiobiAdminEntityApi,
  entityId: string,
  entityStatus: EntityStatus,
): Promise<EntityStatusAction[]> {
  switch (entityStatus) {
    case EntityStatus.UNVERIFIED:
      return [
        ...(await displayBankOptions(entityApi, Number(entityId))
          ? [EntityStatusAction.BANKCUSTOMER, EntityStatusAction.NONBANKCUSTOMER]
          : []),
        ...(await canVerifyEntity(fileApi, entityId)
          ? [EntityStatusAction.VERIFYLEVELONE]
          : []),
        EntityStatusAction.REJECT,
      ];
    case EntityStatus.VERIFYLEVELONE:
      return [
        ...await checkIsBankingCustomer(entityApi, Number(entityId))
          ? [EntityStatusAction.REJECT, EntityStatusAction.SUBMITDOCUMENTS]
          : [EntityStatusAction.REJECT, EntityStatusAction.VERIFY],
      ];
    case EntityStatus.VERIFIED: return [
      EntityStatusAction.REJECT,
    ];
    case EntityStatus.SUBMITED_TO_CHOICE: return [
      EntityStatusAction.SUBMITENTITY,
    ];
    // case EntityStatus.UNVERIFIED: return [
    //   ...await canVerifyEntity(fileApi, entityId) ? [EntityStatusAction.VERIFYLEVELONE] : [],
    // ];
    case EntityStatus.SUBMITED_ENTITY_ONBOARDING_TO_CHOICE: return [
      EntityStatusAction.REJECT,
      EntityStatusAction.VERIFY,
    ];
    default: {
      console.warn(`getEntityStatusActions unhandled status: ${entityStatus}`);
      return [];
    }
  }
}

async function canVerifyEntity(
  fileApi: NiobiAdminEntityFileApi,
  entityId: string,
): Promise<boolean> {
  return fileApi.findAll({
    documentEntityIds: [entityId],
    limit: 100,
  })
    .then((response) => {
      const entityFiles = response.items;
      return entityFiles.length > 0
            && entityFiles.every((file) => file.status === EntityFileStatus.APPROVED);
    })
    .catch((err) => {
      console.log('Failed to get entity files', err);
      return false;
    });
}

async function checkIsBankingCustomer(
  entityAPi:NiobiAdminEntityApi,
  entityId:number,
):Promise<boolean> {
  return entityAPi.findOne(entityId).then((response) => {
    const entity = response;
    return entity.isBankCustomer;
  })
    .catch((err:any) => {
      console.log('Failed to get entity files', err);
      return false;
    });
}

async function displayBankOptions(
  entityAPi:NiobiAdminEntityApi,
  entityId:number,
):Promise<boolean> {
  return entityAPi.findOne(entityId).then((response) => {
    const entity = response;
    return entity.isBankCustomer === null;
  })
    .catch((err:any) => {
      console.log('Failed to get entity files', err);
      return false;
    });
}

export function getNewEntityStatus(action: EntityStatusAction): EntityStatus {
  switch (action) {
    case EntityStatusAction.VERIFY: return EntityStatus.VERIFIED;
    case EntityStatusAction.REJECT: return EntityStatus.UNVERIFIED;
    case EntityStatusAction.VERIFYLEVELONE: return EntityStatus.VERIFYLEVELONE;
    case EntityStatusAction.SUBMITDOCUMENTS: return EntityStatus.SUBMITED_TO_CHOICE;
    case EntityStatusAction.SUBMITENTITY: return EntityStatus.SUBMITED_ENTITY_ONBOARDING_TO_CHOICE;
    default: {
      console.error(`getNewEntityStatus unhandled action: ${action}`);
      return undefined as unknown as EntityStatus;
    }
  }
}
