import axios from 'axios';
import * as qs from 'qs';
import { Paginated, PaginationQuery } from '../../Pagination';
import { ADMIN_API_HOST } from '../../Config';
import { EntityFile, EntityFileStatus } from './EntityFile';
import { DocumentStatus } from './Document';
import { DocumentCreateDto } from './NiobiAdminDocumentApi';
import { addUnauthorizedInterceptor } from '../../ApiUtils';

export class NiobiAdminEntityFileApi {
  private readonly api;

  constructor(
    private readonly accessToken: string | undefined,
    private readonly onUnauthorized: VoidFunction,
  ) {
    this.api = axios.create({
      baseURL: `${ADMIN_API_HOST}/entityFile/`,
      headers: { Authorization: `Bearer ${accessToken}` },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });
    addUnauthorizedInterceptor(this.api, onUnauthorized);
  }

  async create(dto: EntityFileCreateDto): Promise<EntityFile> {
    return this.api.post('/', dto)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to create entity file', err.response?.data);
        throw new Error('Unable to upload document at the moment. Please try again later.');
      });
  }

  async findOne(id: number): Promise<EntityFile> {
    return this.api.get(`/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query entity file', err.response?.data);
        throw new Error('Unable to fetch document at the moment. Please try again later.');
      });
  }

  async findAll(query?: EntityFileQueryDto): Promise<Paginated<EntityFile>> {
    return this.api.get('/', {
      params: query,
    })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query entity files', err.response?.data);
        throw new Error('Unable to fetch documents at the moment. Please try again later.');
      });
  }

  async update(id: number, update: EntityFileUpdateDto): Promise<EntityFile> {
    return this.api.patch(`/${id}`, update)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to update entity file', err.response?.data);
        throw new Error('Unable to update document at the moment. Please try again later.');
      });
  }
}

export type EntityFileCreateDto = {
  document: DocumentCreateDto;
  comment?:string;
  title: string;
  url: string;
  status: EntityFileStatus;
};

export type EntityFileQueryDto = {
  ids?: number[];
  sortField?: string;
  documentIds?: string[];
  titleLike?: string;
  status?: EntityFileStatus;
  uploadedBefore?: Date;
  uploadedAfter?: Date;
  createdBefore?: Date;
  createdAfter?: Date;
  updatedBefore?: Date;
  updatedAfter?: Date;
  // document
  documentUserIds?: string[];
  documentEntityIds?: string[];
  documentDocumentType?: string;
  documentType?: string;
  documentStatus?: DocumentStatus;
  // document user
  documentUserFirstNameLike?: string;
  documentUserLastNameLike?: string;
  documentUserNameLike?: string;
  documentUserEmail?: string;
  // document entity
  documentEntityNameLike?: string;
  documentEntityRegistrationNumber?: string;
} & PaginationQuery;

export type EntityFileUpdateDto = Partial<Omit<EntityFileCreateDto, 'document'>>
& { document?: Partial<DocumentCreateDto> };
