import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Settlement } from './Settlement';
import { Entity } from '../entities/Entity';

function CreateSettlementDialog(props: {
  open: boolean,
  onClose: VoidFunction,
  entity: Entity | undefined,
  onConfirm: (formValues: Settlement) => void,
}) {
  const {
    open, onClose, onConfirm, entity,
  } = props;
  console.log('entity for settlement', entity);

  const [formValues, setFormValues] = React.useState({
    user: '',
    country: '',
    currency: '',
    payinFee: '',
    payoutFee: '',
    settlementDays: '',
  });

  useEffect(() => {
    if (entity !== undefined) {
      setFormValues({
        user: entity.name !== undefined ? entity.name : '',
        country: '',
        currency: '',
        payinFee: '',
        payoutFee: '',
        settlementDays: '',
      });
    }
  }, [entity]);

  const handleChange = (event:any) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Create New Settlement
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              name="user"
              value={formValues.user}
              disabled={entity !== undefined}
              variant="outlined"
              onChange={handleChange}
            />
            <FormControl variant="outlined">
              <InputLabel>Country</InputLabel>
              <Select
                name="country"
                value={formValues.country}
                onChange={handleChange}
                label="Country"
              >
                <MenuItem value=""><em>None</em></MenuItem>
                <MenuItem value="country1">Country 1</MenuItem>
                <MenuItem value="country2">Country 2</MenuItem>
                <MenuItem value="country3">Country 3</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel>Currency</InputLabel>
              <Select
                name="currency"
                value={formValues.currency}
                onChange={handleChange}
                label="Currency"
              >
                <MenuItem value=""><em>None</em></MenuItem>
                <MenuItem value="country1">KES</MenuItem>
                <MenuItem value="country2">TZS</MenuItem>
                <MenuItem value="country3">RWF</MenuItem>
                <MenuItem value="country3">USD</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="payinFee"
              label="Payin Fee"
              variant="outlined"
              value={formValues.payinFee}
              onChange={handleChange}
            />
            <TextField
              name="payoutFee"
              label="Payout Fee"
              variant="outlined"
              value={formValues.payoutFee}
              onChange={handleChange}
            />
            <TextField
              name="settlementDays"
              label="Settlement Days"
              variant="outlined"
              value={formValues.settlementDays}
              onChange={handleChange}
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={() => onConfirm(formValues)} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateSettlementDialog;
