import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import logo from '../../assets/Niobi_dark_new.png';

export default function AppLoadingScreen() {
  return (
    <Box className="app-loading-screen">
      <div className="inner-container">
        <img className="logo" src={logo} alt="niobi-logo" />
        <LinearProgress sx={{ width: '100%' }} />
      </div>
    </Box>
  );
}
