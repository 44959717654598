import { User } from './User';

export type Entity = {
  id: number;
  name: string;
  registrationNumber: string;
  userId: string;
  user: User;
  entityTypeId: number;
  entityType: EntityType;
  entityActivityId: number;
  entityActivity: EntityActivity;
  description?: string;
  moniteId?: string;
  type?: string;
  legalName?: string;
  vatId?: string;
  legalEntityId?: string;
  taxIdentificationNumber?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  createdAt?: Date;
  updatedAt?: Date;
  status: EntityStatus;
  isBankCustomer: boolean;
};

export enum EntityStatus {
  NEW = 'NEW',
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  SUBMITED_TO_CHOICE = 'SUBMITTED CHOICE DOCUMENTS',
  VERIFYLEVELONE = 'VERFIED LEVEL 1',
  SUBMITED_ENTITY_ONBOARDING_TO_CHOICE = 'SUBMITTED ENTITY',
}

export const EntityStatuses: EntityStatus[] = Object.values(EntityStatus);

export function formatEntityStatus(status: EntityStatus): string {
  switch (status) {
    case EntityStatus.NEW: return 'New';
    case EntityStatus.VERIFIED: return 'Verified';
    case EntityStatus.UNVERIFIED: return 'Unverified';
    case EntityStatus.SUBMITED_TO_CHOICE: return 'Awaiting Choice review';
    case EntityStatus.VERIFYLEVELONE: return 'Verified by Niobi';
    case EntityStatus.SUBMITED_ENTITY_ONBOARDING_TO_CHOICE: return 'Submitted to Choice';
    default: {
      console.warn(`formatEntityStatus unhandled status: ${status}`);
      return status;
    }
  }
}

export type EntityActivity = {
  id: number;
  name: string;
  description?: string;
};

export type EntityType = {
  id: number;
  name: string;
  description?: string;
};
