import React from 'react';
import Chart from 'react-apexcharts';

function DocumentChart(props: {
  chartOptions: any,
  chartSeries: any,
}) {
  const {
    chartOptions, chartSeries,
  } = props;
  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type="donut" width="520" />
    </div>
  );
}

export default DocumentChart;
