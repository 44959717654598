import axios from 'axios';
import * as qs from 'qs';
import { Paginated, PaginationQuery } from '../../Pagination';
import { Audit } from './Audit';
import { ADMIN_API_HOST } from '../../Config';
import { addUnauthorizedInterceptor } from '../../ApiUtils';

export class NiobiAdminAuditApi {
  private readonly api;

  constructor(
    private readonly accessToken: string | undefined,
    private readonly onUnauthorized: VoidFunction,
  ) {
    this.api = axios.create({
      baseURL: `${ADMIN_API_HOST}/audits/`,
      headers: { Authorization: `Bearer ${accessToken}` },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });
    addUnauthorizedInterceptor(this.api, onUnauthorized);
  }

  async findOne(id: number): Promise<Audit> {
    return this.api.get(`/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query Audit', err.response?.data);
        throw new Error('Unable to fetch Audit at the moment. Please try again later.');
      });
  }

  async findAll(query?: AuditQueryDto): Promise<Paginated<Audit>> {
    return this.api.get('/', {
      params: query,
    })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query entities', err.response?.data);
        throw new Error('Unable to fetch audits at the moment. Please try again later.');
      });
  }

  async update(id: number, update: AuditUpdateDto): Promise<Audit> {
    console.log('Audit to be updated', update);
    return this.api.patch(`/${id}`, update)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to update Audit', err.response?.data);
        throw new Error('Unable to update Audit at the moment. Please try again later.');
      });
  }
}

export type AuditQueryDto = {
  ids?: number[];
  entityIds?: string[];
  descriptionLike?: string;
  performedBy?: string;
  eventLike?: string;
  createdBefore?: Date;
  createdAfter?: Date;
  updatedBefore?: Date;
  updatedAfter?: Date;
} & PaginationQuery;

export type AuditUpdateDto = Omit<Partial<Audit>, 'id'>;
