import { Box, Button, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateSettlementDialog from '../settlements/CreateSettlementDialog';
import { Settlement } from '../settlements/Settlement';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminEntityApi } from '../entities/NiobiAdminEntityApi';
import { Entity } from '../entities/Entity';

function CustomFeesScreen() {
  const getRandomDate = (start: any, end: any) => {
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  };
  const location = useLocation();
  const selectedEntity = location.state?.selectedEntity;

  const navigate = useNavigate();

  console.log('selectedEntity', selectedEntity);

  const { entities, isLoading, errorMessage } = useEntities(selectedEntity);

  console.log('entities', entities);
  console.log('isLoading', isLoading);
  console.log('errorMessage', errorMessage);
  const [rows, setRows] = React.useState([
    {
      id: 1,
      user: 'User 1',
      country: 'Country 1',
      currency: 'USD',
      payin: '2.5%',
      payout: '1.0%',
      settlementDays: '3',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 2,
      user: 'User 2',
      country: 'Country 2',
      currency: 'EUR',
      payin: '2.0%',
      payout: '1.2%',
      settlementDays: '2',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 3,
      user: 'User 3',
      country: 'Country 3',
      currency: 'GBP',
      payin: '1.8%',
      payout: '0.8%',
      settlementDays: '4',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 4,
      user: 'User 4',
      country: 'Country 4',
      currency: 'JPY',
      payin: '3.0%',
      payout: '1.5%',
      settlementDays: '1',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 5,
      user: 'User 5',
      country: 'Country 5',
      currency: 'AUD',
      payin: '2.2%',
      payout: '1.1%',
      settlementDays: '5',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 6,
      user: 'User 6',
      country: 'Country 6',
      currency: 'CAD',
      payin: '2.7%',
      payout: '1.3%',
      settlementDays: '3',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 7,
      user: 'User 7',
      country: 'Country 7',
      currency: 'CHF',
      payin: '2.1%',
      payout: '0.9%',
      settlementDays: '2',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 8,
      user: 'User 8',
      country: 'Country 8',
      currency: 'CNY',
      payin: '1.9%',
      payout: '1.4%',
      settlementDays: '4',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 9,
      user: 'User 9',
      country: 'Country 9',
      currency: 'INR',
      payin: '2.3%',
      payout: '1.2%',
      settlementDays: '1',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
    {
      id: 10,
      user: 'User 10',
      country: 'Country 10',
      currency: 'BRL',
      payin: '2.8%',
      payout: '1.0%',
      settlementDays: '3',
      createdAt: getRandomDate(new Date(2023, 0, 1), new Date()),
      updatedAt: getRandomDate(new Date(2023, 0, 1), new Date()),
    },
  ]);
  const columns = [
    // { field: 'user', headerName: 'User', flex: 1 },
    {
      field: 'country', headerName: 'Country', width: 100, flex: 1,
    },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'payin', headerName: 'Payin Fee', width: 100 },
    { field: 'payout', headerName: 'Payout Fee', width: 100 },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
    { field: 'updatedAt', headerName: 'Updated At', width: 200 },
  ];

  const [createAction, setCreateAction] = React.useState<Boolean>(false);

  const handleAddnewRow = () => {
    setCreateAction(true);
  };

  const handleCancelCreate = () => {
    setCreateAction(false);
  };

  const handleConfirmCreate = (settlement: Settlement) => {
    setCreateAction(false);
    const newRow = {
      id: Math.floor(Math.random() * 1000),
      country: settlement.country,
      currency: settlement.currency,
      user: settlement.user,
      settlementDays: settlement.settlementDays,
      payin: settlement.payinFee,
      payout: settlement.payoutFee,
      createdAt: new Date().toISOString().split('T')[0],
      updatedAt: new Date().toISOString().split('T')[0],
    };
    setRows([...rows, newRow]);
  };
  return (
    <div>
      <Button onClick={() => navigate(-1)}>go back</Button>
      <h2>Custom Fees</h2>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={handleAddnewRow}>Create new</Button>
      </div>
      <Box sx={(theme) => ({
        height: 450, // Ensure the box has enough height
        width: '100%', // Make sure it takes the full width
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        flex: 1,
      })}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={false}
          density="compact"
          slots={{ loadingOverlay: LinearProgress }}
        />
      </Box>
      <CreateSettlementDialog
        onClose={handleCancelCreate}
        entity={entities}
        onConfirm={handleConfirmCreate}
        open={!!createAction}
      />
    </div>
  );
}

export default CustomFeesScreen;

export function useEntities(entityId: number) {
  const auth = useAuth();
  const api = useMemo(() => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout), [auth]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [entities, setEntities] = useState<Entity>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const fetchEntities = useCallback(() => {
    setIsLoading(true);
    api.findOne(entityId)
      .then((response) => {
        setEntities(response);
      })
      .then(() => setErrorMessage(undefined))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [api, entityId]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchEntities();
  }, [fetchEntities, isInitialRender]);

  return {
    isLoading,
    entities,
    errorMessage,
    refresh: fetchEntities,
  };
}
