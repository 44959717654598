import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Chart from 'react-apexcharts';
import AnalyticsCard from './AnalyticsCard';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminEntityApi } from '../entities/NiobiAdminEntityApi';
import { NiobiAdminDocumentApi } from '../entityfiles/NiobiAdminDocumentApi';
import DocumentChart from './DocumentChart';

export default function OverviewScreen() {
  const {
    isLoading,
    entitiesStats,
  } = useEntities();

  const {
    filesStats,
  } = useEntityFiles();

  console.log('entitiesStats', entitiesStats);
  console.log('isLoading', isLoading);

  const chartOptions = {
    type: 'pie',
    labels: ['New', 'Verified by Niobi', 'Awaiting Choice Review', 'Submitted to Choice', 'Unverified', 'Verified'],
    colors: ['#458F7D', '#21144C', '#D1D1D1', '#19403A', '#E55F5F', '#9B7A73'],
    legend: {
      fontSize: '14px',
      offsetY: 40,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  };

  const documentsOptions = {
    type: 'donut',
    labels: ['Pending', 'Waiting for Approval', 'Approved', 'Rejected'],
    legend: {
      fontSize: '14px',
      horizontalAlign: 'center',
      position: 'right',
      offsetY: 80,
    },
    colors: ['#9B7A73', '#458F7D', '#19403A', '#D1D1D1'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  };

  let documentsSeries;
  if (filesStats) {
    documentsSeries = [...Object.values(filesStats)] as any;
  } else {
    documentsSeries = [0, 0, 0, 0, 0, 0];
  } // Values for each label

  let chartSeries;
  if (entitiesStats) {
    chartSeries = [...Object.values(entitiesStats)] as any;
  } else {
    chartSeries = [0, 0, 0, 0, 0, 0];
  } // Values for each label
  return (
    <div>
      <h3>Overview</h3>
      <h4>Entity Analytics</h4>
      {entitiesStats && (
      <div
        style={
        {
          display: 'flex',
          justifyContent: 'space-between',
          width: '90%',
          gap: '1rem',
        }
        }
      >
        <AnalyticsCard data={entitiesStats} />
        <Chart options={chartOptions} series={chartSeries} type="pie" width="520" />
      </div>
      )}
      <h4>Document Analytics</h4>

      {filesStats && (
      <div
        style={
        {
          display: 'flex',
          justifyContent: 'space-between',
          width: '90%',
          gap: '1rem',
        }
        }
      >
        <AnalyticsCard data={filesStats} />
        <DocumentChart chartOptions={documentsOptions} chartSeries={documentsSeries} />
      </div>
      )}
    </div>
  );
}

export function useEntities() {
  const auth = useAuth();
  const api = useMemo(() => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout), [auth]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [entitiesStats, setEntitiesStats] = React.useState<any>(null);
  // const [totalNumEntities, setTotalNumEntities] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();

  const fetchEntitiesStats = useCallback(() => {
    setIsLoading(true);
    api.getStats()
      .then((response) => {
        setEntitiesStats(response);
      })
      .then(() => setErrorMessage(undefined))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [
    api,
  ]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchEntitiesStats();
  }, [fetchEntitiesStats, isInitialRender]);

  return {
    isLoading,
    entitiesStats,
    errorMessage,
  };
}

function useEntityFiles() {
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminDocumentApi(auth.user?.accessToken, auth.logout),
    [auth],
  );

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [filesStats, setFilesStats] = React.useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  const fetchFilesStats = useCallback(() => {
    setIsLoading(true);
    api.getStats()
      .then((response) => {
        setFilesStats(response);
      })
      .then(() => setErrorMessage(undefined))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [
    api,
  ]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchFilesStats();
  }, [fetchFilesStats, isInitialRender]);

  return {
    isLoading,
    filesStats,
    errorMessage,
  };
}
