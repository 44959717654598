import * as React from 'react';
import { GridPaginationModel, GridSortItem } from '@mui/x-data-grid';
import {
  Box, debounce, Stack, TextField, Toolbar,
} from '@mui/material';
import {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { useAuth } from '../auth/AuthProvider';
import { Audit } from './Audit';
import { NiobiAdminAuditApi } from './NiobiAdminAuditApi';
import { ScreenErrorAlert } from '../../components/ScreenErrorAlert';
import AuditTable from './AuditsTable';
import { INPUT_DEBOUNCE_MS } from '../../Config';

export default function AuditLogs() {
  const {
    isLoading,
    audits,
    totalNumEntities,
    errorMessage,
    refresh,
    setPaginationFilter,
    setEventNameFilter,
    setDescriptionFilter,
    setSortFilter,
    setDoneByFilter,
    setCreatedAtFilter,
  } = useAudits();
  // const [isLoadingStatusUpdate, setIsLoadingStatusUpdate] = useState(false);
  // const [statusUpdateError, setStatusUpdateError] = useState<string>();
  const handleTableQueryChange = (
    paginationModel?: GridPaginationModel,
    sortModel?: GridSortItem[],
  ) => {
    setPaginationFilter(paginationModel);
    setSortFilter(sortModel);
  };

  const handleEventNameFilterChange = (value?: string) => {
    setEventNameFilter(value || undefined);
  };

  const handleDescriptionFilterChange = (value?: string) => {
    setDescriptionFilter(value || undefined);
  };

  const handleDoneByFilterChange = (value?: string) => {
    setDoneByFilter(value || undefined);
  };

  const handleCreatedAfterChange = (value?: Date) => {
    setCreatedAtFilter(value || undefined);
  };

  return (
    <Box className="entities-screen">
      <h3>Audits logs</h3>
      <ScreenErrorAlert className="alert" isLoading={isLoading} errorMessage={errorMessage} onRetry={refresh} />
      {/* <ScreenErrorAlert
        className="alert"
        isLoading={isLoadingStatusUpdate}
        errorMessage={statusUpdateError}
        onClose={() => setStatusUpdateError(undefined)}
      /> */}
      <Toolbar disableGutters className="toolbar">
        <Stack direction="row" spacing={1}>

          <TextField
            className="toolbar-input"
            label="Event Name"
            variant="outlined"
            onChange={debounce(
              (event) => handleEventNameFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
          <TextField
            className="toolbar-input"
            label="Description"
            variant="outlined"
            onChange={debounce(
              (event) => handleDescriptionFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
          <TextField
            className="toolbar-input"
            label="Done by"
            variant="outlined"
            onChange={debounce(
              (event) => handleDoneByFilterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
          <TextField
            id="datetime-local"
            label="Created After"
            type="datetime-local"
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={debounce(
              (event) => handleCreatedAfterChange(event?.target.value || undefined),
              INPUT_DEBOUNCE_MS,
            )}
          />
        </Stack>
      </Toolbar>
      <AuditTable
        isLoading={isLoading}
        audits={audits}
        totalNumItems={totalNumEntities}
        onQueryChange={handleTableQueryChange}
      />
    </Box>
  );
}

export function useAudits() {
  const auth = useAuth();
  const api = useMemo(() => new NiobiAdminAuditApi(auth.user?.accessToken, auth.logout), [auth]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [audits, setAudits] = useState<Audit[]>([]);
  const [totalNumEntities, setTotalNumEntities] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [paginationFilter, setPaginationFilter] = useState<GridPaginationModel>();
  const [sortFilter, setSortFilter] = useState<GridSortItem[]>();
  const [createdAtFilter, setCreatedAtFilter] = useState<Date>();
  const [eventNameFilter, setEventNameFilter] = useState<string>();
  const [descriptionFilter, setDescriptionFilter] = useState<string>();
  const [doneByFilter, setDoneByFilter] = useState<string>();

  const fetchAudits = useCallback(() => {
    const pagination = paginationFilter;
    const sort = sortFilter;
    const createdAt = createdAtFilter;
    const eventName = eventNameFilter;
    const description = descriptionFilter;
    const doneBy = doneByFilter;

    const offset = pagination ? pagination.page * pagination.pageSize : undefined;
    const limit = pagination?.pageSize;
    const sortField = sort?.[0]?.field || 'id';
    const sortAsc = sort?.[0]?.sort ? sort[0].sort !== 'desc' : false;

    setIsLoading(true);
    api.findAll({
      offset,
      limit,
      sortField,
      sortAsc,
      eventLike: eventName,
      descriptionLike: description,
      performedBy: doneBy,
      createdAfter: createdAt,
    })
      .then((response) => {
        setAudits(response.items);
        setTotalNumEntities(response.paginationInfo.totalNumItems);
      })
      .then(() => setErrorMessage(undefined))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [
    api,
    paginationFilter,
    sortFilter,
    descriptionFilter,
    eventNameFilter,
    doneByFilter,
    createdAtFilter,
  ]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchAudits();
  }, [fetchAudits, isInitialRender]);

  return {
    isLoading,
    audits,
    totalNumEntities,
    errorMessage,
    refresh: fetchAudits,
    setPaginationFilter,
    setSortFilter,
    setCreatedAtFilter,
    setEventNameFilter,
    setDescriptionFilter,
    setDoneByFilter,
  };
}
