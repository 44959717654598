import axios from 'axios';
import * as qs from 'qs';
import { Paginated, PaginationQuery } from '../../Pagination';
import { Document, DocumentStatus } from './Document';
import { ADMIN_API_HOST } from '../../Config';
import { addUnauthorizedInterceptor } from '../../ApiUtils';

export class NiobiAdminDocumentApi {
  private readonly api;

  constructor(
    private readonly accessToken: string | undefined,
    private readonly onUnauthorized: VoidFunction,
  ) {
    this.api = axios.create({
      baseURL: `${ADMIN_API_HOST}/document/`,
      headers: { Authorization: `Bearer ${accessToken}` },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });
    addUnauthorizedInterceptor(this.api, onUnauthorized);
  }

  async findOne(id: number): Promise<Document> {
    return this.api.get(`/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query document', err.response?.data);
        throw new Error('Unable to fetch document at the moment. Please try again later.');
      });
  }

  async findAll(query?: DocumentQueryDto): Promise<Paginated<Document>> {
    return this.api.get('/', {
      params: query,
    })
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to query documents', err.response?.data);
        throw new Error('Unable to fetch documents at the moment. Please try again later.');
      });
  }

  async update(id: number, update: DocumentUpdateDto): Promise<Document> {
    return this.api.patch(`/${id}`, update)
      .then((response) => response.data)
      .catch((err) => {
        console.error('Failed to update document', err.response?.data);
        throw new Error('Unable to update document at the moment. Please try again later.');
      });
  }

  async getStats(): Promise<void> {
    return this.api.get('/stats')
      .then((response) => response.data)
      .catch((err) => {
        console.error('Unable to get stats', err.response?.data);
        throw new Error('Unable to get analytics at the moment. Please try again later.');
      });
  }
}

export type DocumentCreateDto = {
  userId: string;
  entityId: string;
  documentType: string;
  type: string;
  status: DocumentStatus;
};

export type DocumentQueryDto = {
  ids?: number[];
  sortField?: string;
  userIds?: string[];
  entityIds?: string[];
  documentType?: string;
  type?: string;
  status?: DocumentStatus;
  createdBefore?: Date;
  createdAfter?: Date;
  updatedBefore?: Date;
  updatedAfter?: Date;
  // user
  userFirstNameLike?: string;
  userLastNameLike?: string;
  userNameLike?: string;
  userEmail?: string;
  // entity
  entityNameLike?: string;
  entityRegistrationNumber?: string;
} & PaginationQuery;

export type DocumentUpdateDto = Omit<Partial<Document>, 'id'>;
