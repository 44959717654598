import React from 'react';

export default function AnalyticsCard(props: {
  data: any,
}) {
  const {
    data,
  } = props;
  return (
    <div
      style={
      {
        width: '40%',
        height: 'fit-content',
        padding: '0.5rem',
        border: '1px solid #025141',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }
      }
    >
      {Object.entries(data).map(([key, value]) => (
        <div style={{
          display: 'flex',
          height: '2rem',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <p>{`${key}`}</p>
          <p>{value as React.ReactNode}</p>
        </div>
      ))}
    </div>
  );
}
