import React from 'react';
import './App.scss';
import { Box, CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from './features/auth/AuthProvider';
import LoginScreen from './features/login/LoginScreen';
import DashboardScreen from './features/dashboard/DashboardScreen';
import AppLoadingScreen from './features/auth/AppLoadingScreen';
import OverviewScreen from './features/overview/OverviewScreen';
import RequireAuth from './features/auth/RequireAuth';
import EntitiesScreen from './features/entities/EntitiesScreen';
import EntityFilesScreen from './features/entityfiles/EntityFilesScreen';
import SingleEntityFiles from './features/entityfiles/SingleEntityFiles';
import AuditLogs from './features/audit/AuditLogs';
import SettlementsScreen from './features/settlements/SettlementsScreen';
import CustomFeesScreen from './features/fees/CustomFeesScreen';

function App() {
  const auth = useAuth();
  if (auth.isLoading) return <AppLoadingScreen />;
  return (
    <Box className="app-container">
      <CssBaseline />
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route element={<RequireAuth><DashboardScreen /></RequireAuth>}>
          <Route path="/" element={<OverviewScreen />} />
          <Route
            path="/documents"
            element={<EntityFilesScreen />}
          />
          <Route
            path="/entities"
            element={<EntitiesScreen />}
          />
          <Route
            path="/entities/:id"
            element={<SingleEntityFiles />}
          />
          <Route
            path="/audit"
            element={<AuditLogs />}
          />
          <Route
            path="/settlements"
            element={<SettlementsScreen />}
          />
          <Route
            path="/custom-fees"
            element={<CustomFeesScreen />}
          />
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
