import React, { useState } from 'react';
import {
  Button,
  TextField,
  Modal,
  Box,
  Typography,
  MenuItem,
} from '@mui/material';
import { MAIN_API_HOST } from '../../Config';

interface CreateEntityModalProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export default function CreateEntityModal({ open, onClose, onOpen }: CreateEntityModalProps) {
  const [formData, setFormData] = useState({
    business_name: '',
    company_type: '',
    business_activity: '',
    street_address: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    email: '',
  });

  const handleOpen = () => onOpen();
  const handleClose = () => onClose();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      business_name: '',
      company_type: '',
      business_activity: '',
      street_address: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      email: '',
    });
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    try {
      const response = await fetch(`${MAIN_API_HOST}/api/create-entity-manually`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          data: {
            business_name: formData.business_name,
            company_type: formData.company_type,
            business_activity: formData.business_activity,
            street_address: formData.street_address,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            zip_code: formData.zip_code,
          },
        }),
      });

      if (response.ok) {
        alert('Entity created successfully!');
        handleReset();
        handleClose();
      } else {
        alert('Failed to create entity.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
      <Button
        style={{ position: 'absolute', right: '10px' }}
        color="primary"
        variant="contained"
        onClick={handleOpen}
      >
        Create New Entity
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Create New Entity
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Business Name"
              name="business_name"
              value={formData.business_name}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="User Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Company Type"
              name="company_type"
              value={formData.company_type}
              onChange={handleChange}
              select
            >
              <MenuItem value="1">Sole Proprietorship</MenuItem>
              <MenuItem value="2">Unlimited Partnership/Limited Liability Partnerships</MenuItem>
              <MenuItem value="3">Limited Company</MenuItem>
            </TextField>
            <TextField
              fullWidth
              margin="normal"
              label="Business Activity"
              name="business_activity"
              value={formData.business_activity}
              onChange={handleChange}
              select
            >
              <MenuItem value="1">Technology</MenuItem>
              <MenuItem value="2">Financial Services</MenuItem>
              <MenuItem value="3">Energy</MenuItem>
              <MenuItem value="4">Agriculture</MenuItem>
              <MenuItem value="5">Real Estate</MenuItem>
              <MenuItem value="6">Transport and Logistics</MenuItem>
              <MenuItem value="7">Manufacturing</MenuItem>
              <MenuItem value="8">Tourism</MenuItem>
              <MenuItem value="9">Professional Services</MenuItem>
              <MenuItem value="10">Other</MenuItem>
            </TextField>
            <TextField
              fullWidth
              margin="normal"
              label="Street Address"
              name="street_address"
              value={formData.street_address}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="State"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Zip Code"
              name="zip_code"
              value={formData.zip_code}
              onChange={handleChange}
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button onClick={handleReset} color="secondary">
                Reset
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
