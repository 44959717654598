import * as React from 'react';
import { GridColDef, GridSortItem } from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
// import moment from 'moment';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PendingIcon from '@mui/icons-material/Pending';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Link from '@mui/material/Link';
import NiobiTable from '../../components/NiobiTable';
import { EntityFile, EntityFileStatus, formatEntityFileStatus } from './EntityFile';
import { EntityFileStatusAction, getEntityFileStatusActions } from './EntityFileStatusEngine';
import { NiobiAdminEntityFileApi } from './NiobiAdminEntityFileApi';
import { useAuth } from '../auth/AuthProvider';
import { useEntities } from '../entities/EntitiesScreen';

export default function EntityFileTable(props: {
  isLoading: boolean,
  files: EntityFile[],
  totalNumItems: number,
  onQueryChange: (paginationModel?: GridPaginationModel, sortModel?: GridSortItem[]) => any,
  onEntityFileStatusAction: OnEntityFileStatusAction,
  onClickUpdate: (file: EntityFile) => any,
  onClickDelete?: (file: EntityFile) => any,
}) {
  const {
    isLoading,
    onQueryChange,
    totalNumItems,
    files,
    onEntityFileStatusAction,
    onClickUpdate,
    onClickDelete,
  } = props;
  const [selectedFiles, setSelectedFiles] = React.useState<EntityFile[]>([]);

  console.log('Loaded files from the Entity Files Tables', files);
  console.log(onClickDelete);

  const handleBulkStatusUpdate = (action: EntityFileStatusAction) => {
    selectedFiles.forEach((file) => onEntityFileStatusAction(file, action));
    setSelectedFiles([]); // Clear selection after update
  };

  // const handleDownload = (url:any, filename:string) => {
  //   console.log('Downloading file***', url, filename);
  //   fetch(url)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //     // Create a blob link element
  //       const link = document.createElement('a');
  //       const blobUrl = URL.createObjectURL(blob);
  //       link.href = blobUrl;
  //       link.setAttribute('download', filename); // Set file name for download
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove(); // Clean up and remove the link
  //       URL.revokeObjectURL(blobUrl); // Free up memory after download
  //     })
  //     .catch((error) => console.error('Download error:', error));
  // };
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 4,
    }}
    >

      {selectedFiles.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleBulkStatusUpdate(EntityFileStatusAction.APPROVE)}
          >
            Approve Selected
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleBulkStatusUpdate(EntityFileStatusAction.WAITING_FOR_APPROVAL)}
          >
            Mark as Waiting for Approval for Selected
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleBulkStatusUpdate(EntityFileStatusAction.WITHDRAW)}
          >
            Withdraw Selected
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleBulkStatusUpdate(EntityFileStatusAction.REJECT)}
          >
            Reject Selected
          </Button>
        </Box>
      )}
      <NiobiTable
        isLoading={isLoading}
        columns={columns(onEntityFileStatusAction, onClickUpdate)}
        rows={files}
        totalNumItems={totalNumItems}
        onQueryChange={onQueryChange}
        isSelectable // Enable row selection for this table
        onSelectionChange={(selectedRows) => setSelectedFiles(selectedRows)}
      />
    </div>
  );
}

const columns = (
  onEntityFileStatusAction: OnEntityFileStatusAction,
  onClickUpdate: (file: EntityFile) => any,
  // handleDownload: (url:string, filename:string) => any,
): GridColDef[] => [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    renderCell: (params) => {
      const file = params.row as EntityFile;
      return <Link href={file.url} target="_blank">{file.title}</Link>;
      // return <button type="button" onClick={() =>
      // handleDownload(file.url as any, file.title as string)}>{file.title}</button>;
    },
  },
  // {
  //   field: 'updatedAt',
  //   headerName: 'Updated',
  //   width: 180,
  //   valueFormatter: (params) => moment(params.value).format('DD MMM YYYY, HH:mm'),
  // },
  {
    field: 'document.user.firstName',
    headerName: 'Submitted by',
    width: 130,
    valueGetter: (params) => {
      const file = params.row as EntityFile;
      const user = file?.document?.user;
      if (!user) return undefined;
      return `${user.firstName} ${user.lastName}`;
    },
  },
  {
    field: 'document.entity.name',
    headerName: 'Entity Name',
    width: 200,
    valueGetter: (params) => (params.row as EntityFile).document?.entity?.name,
  },
  {
    field: 'document.entityType.name',
    headerName: 'Entity Type',
    width: 150,
    valueGetter: (params) => (params.row as EntityFile).document?.entityType?.name,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    align: 'center',
    renderCell: (params) => <EntityFileStatusChip status={(params.row as EntityFile).status} />,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    flex: 1,
    align: 'center',
    renderCell: (params) => {
      const { comment } = params.row as EntityFile;
      return comment === 'NULL' ? ' --' : comment;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <EnfityFileTableRowActions
        file={params.row as EntityFile}
        onStatusAction={onEntityFileStatusAction}
        onClickUpdate={onClickUpdate}
      />
    ),
    align: 'right',
  },
];

function EntityFileStatusChip(props: { status: EntityFileStatus }) {
  const { status } = props;
  const getColor = () => {
    switch (status) {
      case EntityFileStatus.WAITING_FOR_SUBMISSION: return '#9B7A73';
      case EntityFileStatus.WAITING_FOR_APPROVAL: return '#19403A';
      case EntityFileStatus.REJECTED: return '#9B7A73';
      case EntityFileStatus.WITHDRAWN: return '#9B7A73';
      case EntityFileStatus.APPROVED: return '#D1D1D1';
      default: {
        console.warn(`EntityFileStatusChip unhandled status: ${status}`);
        return undefined;
      }
    }
  };
  const getIcon = () => {
    switch (status) {
      case EntityFileStatus.WAITING_FOR_SUBMISSION: return <PendingIcon />;
      case EntityFileStatus.WAITING_FOR_APPROVAL: return <PostAddIcon />;
      case EntityFileStatus.REJECTED: return <CloseIcon />;
      case EntityFileStatus.APPROVED: return <CheckIcon />;
      case EntityFileStatus.WITHDRAWN: return <CloseIcon />;
      default: {
        console.warn(`EntityFileStatusChip unhandled status: ${status}`);
        return undefined;
      }
    }
  };
  return (
    <Tooltip title={formatEntityFileStatus(status)}>
      <Chip
        sx={{ width: '100%', color: getColor() }}
        label={formatEntityFileStatus(status)}
        variant="outlined"
        icon={getIcon()}
      />
    </Tooltip>
  );
}

export type OnEntityFileStatusAction = (file: EntityFile, action: EntityFileStatusAction) => any;

function EnfityFileTableRowActions(props: {
  file: EntityFile,
  onStatusAction: OnEntityFileStatusAction,
  onClickUpdate: (file: EntityFile) => any,
}) {
  const { file, onStatusAction, onClickUpdate } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actionToConfirm, setActionToConfirm] = React.useState<EntityFileStatusAction>();
  const [deleteAction, setDeleteAction] = React.useState<Boolean>(false);
  const { refresh } = useEntities();
  const auth = useAuth();
  const api = React.useMemo(
    () => new NiobiAdminEntityFileApi(auth.user?.accessToken, auth.logout),
    [auth],
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleSelectAction = (action: EntityFileStatusAction) => {
    if (action === EntityFileStatusAction.DELETE) {
      setDeleteAction(true);
    } else {
      setActionToConfirm(action);
    }
  };

  const handleCancelAction = () => {
    handleMenuClose();
    setActionToConfirm(undefined);
  };

  const handleConfirmAction = () => {
    onStatusAction(file, actionToConfirm!);
    handleMenuClose();
    handleCancelAction();
  };

  const handleDeleteCancelAction = () => {
    handleMenuClose();
    setDeleteAction(false);
  };

  const handleDeleteConfirmAction = (comment:string) => {
    handleMenuClose();
    handleDeleteCancelAction();
    api.update(file.id, { comment })
      .then(() => {
        refresh();
      })
      .catch((err) => console.error(err.message));
  };

  const handleUpdateClick = () => {
    onClickUpdate(file);
    handleMenuClose();
  };

  const entityStatusActions = getEntityFileStatusActions(file.status);

  return (
    <>
      {file.status !== EntityFileStatus.APPROVED && (
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleMenuClose}
      >
        {entityStatusActions.map((action) => (
          <MenuItem key={action} onClick={() => handleSelectAction(action)}>{action}</MenuItem>
        ))}
        {file.status === EntityFileStatus.REJECTED && (
          <MenuItem onClick={handleUpdateClick}>Upload</MenuItem>
        )}
      </Menu>
      <EntityFileStatusChangeAlertDialog
        open={!!actionToConfirm}
        onClose={handleCancelAction}
        onConfirm={handleConfirmAction}
      />

      <EntityFileDeleteAlertDialog
        open={!!deleteAction}
        onClose={handleDeleteCancelAction}
        onConfirm={handleDeleteConfirmAction}
      />
    </>
  );
}

function EntityFileStatusChangeAlertDialog(props: {
  open: boolean,
  onClose: VoidFunction,
  onConfirm: VoidFunction,
}) {
  const { open, onClose, onConfirm } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Update File Status
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to update the file status?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function EntityFileDeleteAlertDialog(props: {
  open: boolean,
  onClose: VoidFunction,
  onConfirm: (comment: string) => void,
}) {
  const { open, onClose, onConfirm } = props;
  const [comment, setComment] = React.useState('');

  const handleConfirm = () => {
    onConfirm(comment);
    setComment(''); // Reset comment after confirm
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Delete File
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to update the file status?
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Comment"
          type="text"
          fullWidth
          variant="standard"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
