import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import React, { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
// import { blue } from '@mui/material/colors';
import BusinessIcon from '@mui/icons-material/Business';
import { VerifiedUser } from '@mui/icons-material';
import { useAuth } from '../auth/AuthProvider';

export default function NiobiDrawer() {
  return (
    <Drawer
      className="niobi-drawer"
      variant="permanent"
    >
      <Paper className="inner-container" sx={{ borderRadius: 0 }}>
        <Divider />
        <List>
          <NiobiDrawerItem
            icon={<HomeIcon />}
            title="Overview"
            link="/"
          />
          <NiobiDrawerItem
            icon={<DescriptionIcon />}
            title="Documents"
            link="/documents"
          />
          <NiobiDrawerItem
            icon={<BusinessIcon />}
            title="Entities"
            link="/entities"
          />

          <NiobiDrawerItem
            icon={<VerifiedUser />}
            title="Audit Logs"
            link="/audit"
          />
          {/* <NiobiDrawerItem
            icon={<CurrencyExchange />}
            title="Settlements"
            link="/settlements"
          /> */}
        </List>
        <Box sx={{ flex: 1 }} />
        <Divider />
        <NiobiDrawerUser />
      </Paper>
    </Drawer>
  );
}

function NiobiDrawerItem(props: {
  icon: ReactNode,
  title: string,
  link: string,
}) {
  const { icon, title, link } = props;
  const location = useLocation();
  const isActive = location.pathname.endsWith(link);
  return (
    <Link className="link-unstyled" to={link}>
      <ListItem
        disablePadding
        className={isActive ? 'Mui-selected' : ''}
      >
        <ListItemButton>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}

function NiobiDrawerUser() {
  const auth = useAuth();
  return (
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <Avatar sx={{ bgcolor: '#19403A', width: 36, height: 36 }}>
            {auth.user?.displayName.charAt(0)}
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={(
            <Typography
              variant="body2"
              noWrap
            >
              {auth.user?.displayName}
            </Typography>
          )}
          secondary={(
            <Typography
              variant="body2"
              noWrap
              color="text.secondary"
            >
              {auth.user?.email}
            </Typography>
          )}
        />
      </ListItemButton>
    </ListItem>
  );
}
