import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminEntityApi } from '../entities/NiobiAdminEntityApi';

function SetupProfileDialog(props:{
  open: boolean,
  userId: string,
  onClose: VoidFunction,
}) {
  const {
    open, onClose, userId,
  } = props;

  console.log('uuu', userId);
  const auth = useAuth();
  const api = useMemo(
    () => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout),
    [auth],
  );
  // const [file, setFile] = React.useState(null);
  const [formValues, setFormValues] = React.useState({
    userId,
    status: '',
    fileName: '',
    file: null,
    documentType: '',
    type: '',
    title: '',
  });

  const [profiles, setProfile] = useState([]);

  const fetchProfiles = useCallback(() => {
    api.getProfiles()
      .then((response) => {
        console.log('response', response?.data);
        setProfile(response?.data);
      })
      .catch((err) => console.log(err));
  }, [api]);
  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  console.log('******profiles:', profiles);

  useEffect(() => {
    if (userId !== undefined) {
      setFormValues((prevValues) => ({
        ...prevValues,
        userId,
      }));
    }
  }, [userId]);

  const handleChange = (event:any) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const onConfirm = () => {
    console.log('onConfirm:', formValues);
    // api
    //   .uploadNewFile(formValues)
    //   .then((response) => console.log(response))
    //   .catch((err) => console.log(err.message));
    // .finally(() => setIsUploadingFile(false));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Select Profile
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* <TextField
              name="title"
              label="Title"
              value={formValues.title}
              variant="outlined"
              onChange={handleChange}
            /> */}
            <FormControl variant="outlined">
              <InputLabel>Choose profile</InputLabel>
              <Select
                name="documentType"
                value={formValues.documentType}
                onChange={handleChange}
                label="Country"
              >
                <MenuItem value="1">Local businesses signing up for the app</MenuItem>
                <MenuItem value="2">Customers who use the app and they are interested in banking; mostly foreign entities</MenuItem>
                <MenuItem value="3">Local Businesses Signing Up for API</MenuItem>
                <MenuItem value="4">API power users</MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formValues.status}
                onChange={handleChange}
                label="Status"
              >
                {Object.values(DocumentStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Close</Button>
        <Button color="primary" onClick={onConfirm} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SetupProfileDialog;
