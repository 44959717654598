import axios from 'axios';
import * as qs from 'qs';
import { ADMIN_API_HOST } from '../../Config';
import { addUnauthorizedInterceptor } from '../../ApiUtils';

export class NiobiAdminFileStorageApi {
  private readonly api;

  constructor(
    private readonly accessToken: string | undefined,
    private readonly onUnauthorized: VoidFunction,
  ) {
    this.api = axios.create({
      baseURL: `${ADMIN_API_HOST}/fileStorage/`,
      headers: { Authorization: `Bearer ${accessToken}` },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });
    addUnauthorizedInterceptor(this.api, onUnauthorized);
  }

  async upload(file: File): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.post('/', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => response.data)
      .catch((err) => {
        const isClientError = err.response.status.toString().startsWith('4');
        if (isClientError && err.response.data.message) {
          throw new Error(err.response.data.message);
        }
        console.error('Failed to upload file', err.response?.data);
        throw new Error('Unable to upload document at the moment. Please try again later.');
      });
  }

  async uploadNewFile(payload: any): Promise<string> {
    return this.api.post('/upload-new-file', payload)
      .then((response) => response.data)
      .catch((err) => {
        const isClientError = err.response.status.toString().startsWith('4');
        if (isClientError && err.response.data.message) {
          throw new Error(err.response.data.message);
        }
        console.error('Failed to upload file', err.response?.data);
        throw new Error('Unable to upload document at the moment. Please try again later.');
      });
  }
}

export default NiobiAdminFileStorageApi;
