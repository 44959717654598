import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useAuth } from '../auth/AuthProvider';
import { NiobiAdminEntityApi } from '../entities/NiobiAdminEntityApi';

function OnboardingInfoDialog(props: {
  open: boolean,
  entityId:string,
  onClose: VoidFunction,
}) {
  const {
    open, onClose, entityId,
  } = props;

  console.log(entityId);
  const { onboardingInfo, isLoading, errorMessage } = useEntities(entityId);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (errorMessage) {
    return (
      <p>{`Error: ${errorMessage}`}</p>
    );
  }

  if (!onboardingInfo || !onboardingInfo.response || !onboardingInfo.response.data) {
    return <p>No data available</p>;
  }

  const { data, response } = onboardingInfo;
  const {
    businessAddress,
    businessCertificateNumber,
    businessIndustry,
    businessType,
    countryCode,
    kinCountryCode,
    kinFullName,
    kinMobile,
    kinRelationship,
    krapin,
    mobile,
    onboardingMediaInfos,
    ownerBirthday,
    ownerFullName,
    ownerIdNumber,
    smeName,
  } = response.data;

  console.log('onboardingInfo response', response);
  console.log('onboardingInfo data', data);
  console.log('isLoading', isLoading);
  console.log('errorMessage', errorMessage);
  console.log(onboardingMediaInfos);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Onboading Info
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box component="form" noValidate autoComplete="off" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
            <p>{`business name: ${smeName}`}</p>
            <p>{`business address: ${businessAddress}`}</p>
            <p>{`registration number: ${businessCertificateNumber}`}</p>
            <p>{`business industry: ${businessIndustry}`}</p>
            <p>{`business type: ${businessType}`}</p>
            <p>{`tax number: ${krapin}`}</p>
            <p>{`Owner name: ${ownerFullName}`}</p>
            <p>{`Owner ID: ${ownerIdNumber}`}</p>
            <p>{`Owner birthday: ${ownerBirthday}`}</p>
            <p>{`Owner phone: ${countryCode}${mobile}`}</p>
            <p>{`Kin full name: ${kinFullName}`}</p>
            <p>{`Kin relationship: ${kinRelationship}`}</p>
            <p>{`Kin phone: ${kinCountryCode}${kinMobile}`}</p>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default OnboardingInfoDialog;

export function useEntities(entityId:string) {
  console.log('Entity Id', entityId);
  const auth = useAuth();
  const api = useMemo(() => new NiobiAdminEntityApi(auth.user?.accessToken, auth.logout), [auth]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [onboardingInfo, setOnboardingInfo] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const fetchEntities = useCallback(() => {
    setIsLoading(true);
    api.getOnboardingInfo(entityId)
      .then((response) => {
        setOnboardingInfo(response);
      })
      .then(() => setErrorMessage(undefined))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [api, entityId]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    fetchEntities();
  }, [fetchEntities, isInitialRender]);

  return {
    isLoading,
    errorMessage,
    refresh: fetchEntities,
    onboardingInfo,
  };
}
