import { EntityFileStatus } from './EntityFile';

// todo confirm entity file status engine

export enum EntityFileStatusAction {
  REJECT = 'Reject',
  APPROVE = 'Approve',
  DELETE = 'Delete',
  WITHDRAW = 'Withdraw',
  WAITING_FOR_APPROVAL = 'Waiting For Approval',
}

export function getEntityFileStatusActions(status: EntityFileStatus): EntityFileStatusAction[] {
  switch (status) {
    case EntityFileStatus.WAITING_FOR_SUBMISSION: return [
      EntityFileStatusAction.DELETE, EntityFileStatusAction.WITHDRAW,
      EntityFileStatusAction.WAITING_FOR_APPROVAL];
    case EntityFileStatus.WAITING_FOR_APPROVAL: return [
      EntityFileStatusAction.APPROVE,
      EntityFileStatusAction.REJECT,
    ];
    case EntityFileStatus.APPROVED: return [];
    case EntityFileStatus.REJECTED: return [EntityFileStatusAction.DELETE];
    default: {
      console.warn(`getEntityFileStatusActions unhandled status: ${status}`);
      return [EntityFileStatusAction.DELETE];
    }
  }
}

export function getNewEntityFileStatus(action: EntityFileStatusAction): EntityFileStatus {
  switch (action) {
    case EntityFileStatusAction.REJECT: return EntityFileStatus.REJECTED;
    case EntityFileStatusAction.APPROVE: return EntityFileStatus.APPROVED;
    case EntityFileStatusAction.WITHDRAW: return EntityFileStatus.WITHDRAWN;
    case EntityFileStatusAction.WAITING_FOR_APPROVAL: return EntityFileStatus.WAITING_FOR_APPROVAL;
    default: {
      console.error(`getNewEntityFileStatus unhandled action: ${action}`);
      return undefined as unknown as EntityFileStatus;
    }
  }
}
